import { LoadingOutlined } from '@ant-design/icons'
import { useReactiveVar } from '@apollo/client'
import { Modal, Spin } from 'antd'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { transactionTheGraphModalVar } from '../../../../graphql/variables/TransactionVariable'

export function TheGraphTransactionModal() {
  const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin color='#226AED' />
  const transactionTheGraphModal = useReactiveVar(transactionTheGraphModalVar)
  const [networkIsBusy, setNetworkIsBusy] = useState(false)

  useEffect(() => {
    const handleNetworkIsBusy = () => {
      setTimeout(() => {
        setNetworkIsBusy(true)
      }, 18000)
    }
    handleNetworkIsBusy()
  }, [])

  return (
    <S.TheGraphTransactionModal
      visible={!!transactionTheGraphModal}
      footer={null}
      destroyOnClose
      afterClose={() => setNetworkIsBusy(false)}
    >
      <S.Container>
        <div>
          <Spin indicator={antIcon} />
        </div>
        {networkIsBusy ? (
          <div>
            <h1>The network is busy and estimates are less accurate. Please, hold on a little longer.</h1>
          </div>
        ) : (
          <div>
            <h1>We’re working on it! Just a second.</h1>
          </div>
        )}
      </S.Container>
    </S.TheGraphTransactionModal>
  )
}

export const S = {
  TheGraphTransactionModal: styled(Modal)`
    border-radius: 8px;

    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-content {
      background-color: ${props => props.theme.colors.white};
      border-radius: 16px;
      max-width: 450px;
      height: 280px;
      padding: 25px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-modal-close-x {
      display: none;
    }

    .ant-modal-close {
      position: absolute;
      top: 15px;
      right: -2px;
    }
  `,
  Container: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
      height: 80%;
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 20%;
      margin-top: 20px;
      margin-bottom: 20px;
      h1 {
        text-align: center;
        font-size: 18px;
        line-height: 20px;
        color: ${props => props.theme.colors.gray[4]};
        margin-bottom: 8px;
      }
      span {
        font-size: 14px;
        line-height: 16px;
        color: ${props => props.theme.colors.gray[3]};
      }
    }
  `
}
