import { useReactiveVar } from '@apollo/client'
import closeIcon from '@assets/icons/modal/close-icon.svg'
import metamaskIcon from '@assets/multi-wallet/metamask.svg'
import walletConnectIcon from '@assets/multi-wallet/wallet-connect.svg'
import { accountVar, connectWalletModalVar, MultiWalletProvider } from '@graphql/variables/WalletVariable'
import { multiWalletService } from '@services/MultiWalletService'
import { Button, Checkbox, Modal } from 'antd'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { themeVar } from '../../../graphql/variables/SharedVariable'

interface Wallet {
  key: number
  name: string
  code: MultiWalletProvider
  image: string
  active: boolean
  loading: boolean
  disabled: boolean
}

export const ConnectWalletModal = () => {
  const theme = useReactiveVar(themeVar)
  const account = useReactiveVar(accountVar)
  const connectWalletModal = useReactiveVar(connectWalletModalVar)

  const [walletList, setWalletList] = useState<Wallet[]>([
    {
      key: 0,
      name: 'MetaMask',
      code: MultiWalletProvider.metaMask,
      image: metamaskIcon.src,
      active: false,
      loading: false,
      disabled: true
    },
    {
      key: 1,
      name: 'Wallet Connect',
      code: MultiWalletProvider.walletConnect,
      image: walletConnectIcon.src,
      active: false,
      loading: false,
      disabled: true
    }
  ])

  const setLoading = (key: number | undefined) => {
    const newWalletList: Wallet[] = []
    walletList.forEach(wallet => {
      if (wallet.key === key) {
        const walletUpdate = {
          ...wallet,
          loading: true
        }
        newWalletList.push(walletUpdate)
      } else {
        const walletUpdate = {
          ...wallet,
          loading: false
        }
        newWalletList.push(walletUpdate)
      }
    })
    setWalletList(newWalletList)
  }

  const selectWallet = async (wallet: Wallet) => {
    setLoading(wallet.key)

    await multiWalletService(wallet.code, 1).connect()
    setLoading(undefined)
  }

  useEffect(() => {
    if (account) {
      handleCancel()
    }
  }, [account])

  const handleCancel = () => {
    connectWalletModalVar(false)
  }
  return (
    <S.Modal onCancel={handleCancel} visible={!!connectWalletModal} footer={null} destroyOnClose className={theme}>
      <div>
        <h1>Connect your wallet</h1>
        <Image src={closeIcon} width={16} onClick={handleCancel} />
      </div>
      <div>
        <S.BoxContainer>
          <S.ContainerCards>
            {walletList.map(wallet => (
              <S.CardButton key={wallet.key} onClick={() => selectWallet(wallet)} loading={wallet.loading}>
                <div>
                  <Image alt={wallet.name} src={wallet.image} width={24} height={24} layout='fixed' />
                  <span>{wallet.name}</span>
                </div>
              </S.CardButton>
            ))}
          </S.ContainerCards>
        </S.BoxContainer>
      </div>
    </S.Modal>
  )
}
const S = {
  Modal: styled(Modal)`
    &.dark .ant-modal-body div:first-child,
    &.dark span {
      h1,
      span {
        color: ${({ theme }) => theme.colors.constants.white};
      }
    }
    &.light .ant-modal-body div:first-child,
    &.light span {
      h1,
      span {
        color: ${({ theme }) => theme.colors.constants.black};
      }
      span > img {
        filter: invert(1);
      }
    }
    .ant-modal-header {
      background-color: ${props => props.theme.colors.white};
    }
    .ant-modal-content {
      border-radius: 32px;
      width: 400px;
      height: 290px;
      padding: 32px;
      margin: auto;
      background-color: ${props => props.theme.colors.white};
      padding: 16px 10px;
    }
    .ant-modal-body {
      display: flex;
      padding: 0;
      flex-direction: column;
      padding: 16px;

      > div:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding-top: 4px;
        padding-bottom: 40px;

        > h1 {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 14px;
          text-align: right;
        }
        > span {
          cursor: pointer;
          margin: 0 0 0 auto !important;
        }
      }
    }
    .ant-modal-header {
      padding: 16px 32px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom: none;
    }
    .ant-modal-title {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      padding-bottom: 16px;
      color: ${props => props.theme.colors.gray[4]};
      text-align: center;
      margin-top: 4px;
    }
    .ant-modal-close-x {
      display: flex;
      justify-content: center;
      align-items: center;
      display: none;
      height: 48px;
    }
  `,
  BoxContainer: styled.div`
    display: flex;
    flex-direction: column;

    h3 {
      text-align: center;
      padding: 16px 0;
      padding-bottom: 0;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.black};
      a {
        font-weight: 400;
        text-decoration: underline;
      }
    }
  `,
  ContainerCards: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 12px;
  `,
  CardButton: styled(Button)`
    display: flex;
    width: 100%;
    height: 48px;
    background: ${({ theme }) => theme.colors.gray[0]} !important;
    border-radius: 16px;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    white-space: normal;
    font-family: ${props => props.theme.fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 120%;
    color: ${props => props.theme.colors.black};
    box-shadow: none !important;

    border: none !important;

    &::after {
      display: none;
    }

    &.disabled,
    &.danger {
      background: ${props => props.theme.colors.gray[0]};
      border: 2px solid;
      cursor: not-allowed;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.gray[1]} !important;
      border: none;
    }
    &:focus {
      border-color: ${props => props.theme.colors.secondary.main};
      color: ${props => props.theme.colors.gray[4]};
    }
    div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      img {
        height: 24px;
        width: 24px;
      }
      span:first-child {
        background: #fff !important;
        border-radius: 24px;
        overflow: hidden;
        img {
          width: 16px !important;
          height: 16px !important;
          max-width: 16px !important;
          min-width: 16px !important;
          filter: none !important;
        }
      }
      span:last-child {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 14px;
        text-align: right;
      }
    }
  `,
  Checkbox: styled(Checkbox)`
    color: ${props => props.theme.colors.gray[4]};
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    margin-top: 8px;
    a {
      color: ${props => props.theme.colors.blue.main};
      &:hover {
        opacity: 0.7;
      }
    }
  `
}
