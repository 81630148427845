import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { globalConfig } from '../config'
import { MarketplaceItemBuyer } from './nftfy/backend/marketplace/marketplace-item/MarketplaceItemBuyer'

/**
 * @deprecated use backendV2 instead.
 */
export const graphQlClient = (uri: string) => {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {}
      }
    }
  })

  return new ApolloClient({
    uri,
    cache
  })
}

/**
 * @deprecated use backendV2 instead.
 */
export const genericClient = (uri: string) => {
  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {}
      }
    }
  })

  return new ApolloClient({
    uri,
    cache
  })
}

/**
 * @deprecated use backendV2 instead.
 */
export const backendV1 = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: new HttpLink({
    uri: globalConfig.nftfyBackend.v1
  }),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          MarketplaceItemLiquidity: {
            keyArgs: false,
            merge(existing = [], incoming = []) {
              const mergeData = incoming.filter(
                (incomingItem: { __ref: string }) =>
                  existing.filter((existingItem: { __ref: string }) => existingItem.__ref === incomingItem.__ref).length === 0
              )
              return [...mergeData.concat(existing)]
            }
          }
        }
      }
    }
  })
})

export const backendV2 = new ApolloClient({
  ssrMode: typeof window === 'undefined',
  link: new HttpLink({
    uri: globalConfig.nftfyBackend.v2
  }),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          publicRockpoolItems: {
            keyArgs: ['chainId', 'filterBy', 'orderBy', 'orderDirection'],
            merge(existing = [], incoming = []) {
              const filteredIncoming = incoming.filter(
                (incomingItem: { __ref: string }) =>
                  existing.filter((existingItem: { __ref: string }) => existingItem.__ref === incomingItem.__ref).length === 0
              )
              return [...existing, ...filteredIncoming]
            }
          },
          crowdPads: {
            keyArgs: ['chainId', 'filterBy', 'orderBy', 'orderDirection'],
            merge(existing = [], incoming = []) {
              const filteredIncoming = incoming.filter(
                (incomingItem: { __ref: string }) =>
                  existing.filter((existingItem: { __ref: string }) => existingItem.__ref === incomingItem.__ref).length === 0
              )
              return [...existing, ...filteredIncoming]
            }
          },
          marketplaceItems: {
            keyArgs: ['chainId', 'filterBy', 'orderBy', 'orderDirection'],
            merge(existing = [], incoming = []) {
              const filteredIncoming = incoming.filter(
                (incomingItem: { __ref: string }) =>
                  existing.filter((existingItem: { __ref: string }) => existingItem.__ref === incomingItem.__ref).length === 0
              )
              return [...existing, ...filteredIncoming]
            }
          },
          erc721ByOwner: {
            keyArgs: ['chainIdList', 'ownerAddress'],
            merge(existing = [], incoming = []) {
              const filteredIncoming = incoming.filter(
                (incomingItem: { __ref: string }) =>
                  existing.filter((existingItem: { __ref: string }) => existingItem.__ref === incomingItem.__ref).length === 0
              )
              return [...existing, ...filteredIncoming]
            }
          },
          buyerPublicRockpoolItems: {
            keyArgs: ['buyer', 'chainId'],
            merge(existing = [], incoming = []) {
              const filteredIncoming = incoming.filter(
                (incomingItem: { __ref: string }) =>
                  existing.filter((existingItem: { __ref: string }) => existingItem.__ref === incomingItem.__ref).length === 0
              )
              return [...existing, ...filteredIncoming]
            }
          },
          buyerPrivateRockpoolItems: {
            keyArgs: ['buyer', 'chainId'],
            merge(existing = [], incoming = []) {
              const filteredIncoming = incoming.filter(
                (incomingItem: { __ref: string }) =>
                  existing.filter((existingItem: { __ref: string }) => existingItem.__ref === incomingItem.__ref).length === 0
              )
              return [...existing, ...filteredIncoming]
            }
          },
          erc721TopCollections: {
            keyArgs: ['chainId'],
            merge(existing = [], incoming = []) {
              const filteredIncoming = incoming.filter(
                (incomingItem: { __ref: string }) =>
                  existing.filter((existingItem: { __ref: string }) => existingItem.__ref === incomingItem.__ref).length === 0
              )
              return [...existing, ...filteredIncoming]
            }
          },
          erc721Collections: {
            keyArgs: ['chainId'],
            merge(existing = [], incoming = []) {
              const filteredIncoming = incoming.filter(
                (incomingItem: { collectionAddress: string }) =>
                  existing.filter(
                    (existingItem: { collectionAddress: string }) =>
                      existingItem.collectionAddress.toLocaleLowerCase() === incomingItem.collectionAddress.toLocaleLowerCase()
                  ).length === 0
              )
              return [...existing, ...filteredIncoming]
            }
          },
          erc721ItemsByCollection: {
            keyArgs: ['chainId', 'slug'],
            merge(existing = [], incoming = []) {
              const filteredIncoming = incoming.filter(
                (incomingItem: { tokenId: string }) =>
                  existing.filter((existingItem: { tokenId: string }) => existingItem.tokenId === incomingItem.tokenId).length === 0
              )
              return [...existing, ...filteredIncoming]
            }
          },
          marketplaceItemBuyers: {
            keyArgs: ['erc20Address'],
            merge(existing = [], incoming = []) {
              const filteredIncoming = incoming.filter(
                (incomingItem: MarketplaceItemBuyer) =>
                  existing.filter((existingItem: MarketplaceItemBuyer) => existingItem.ownerAddress === incomingItem.ownerAddress)
                    .length === 0
              )

              return [...existing, ...filteredIncoming]
            }
          },
          rockpoolItemBuyers: {
            keyArgs: ['id', 'ownership'],
            merge(existing = [], incoming = []) {
              const filteredIncoming = incoming.filter(
                (incomingItem: { __ref: string }) =>
                  existing.filter((existingItem: { __ref: string }) => existingItem.__ref === incomingItem.__ref).length === 0
              )
              return [...existing, ...filteredIncoming]
            }
          }
        }
      }
    }
  })
})
