import { chainConfig, globalConfig } from '@config'
import {
  accountVar,
  chainIdVar,
  clearMultiWalletVars,
  MultiWalletProvider,
  setChainIdStorage,
  setProviderStorage,
  web3Var
} from '@graphql/variables/WalletVariable'
import detectEthereumProvider from '@metamask/detect-provider'
import { dataAnalyticsService } from '@services/DataAnalyticsService'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3 from 'web3'
import { code } from '../messages'
import { notifyError, notifyWarning } from './NotificationService'

interface MultiWalletService {
  connect(): Promise<void>
}

export const multiWalletService = (providerName: MultiWalletProvider, chainId: number): MultiWalletService => {
  switch (providerName) {
    case MultiWalletProvider.metaMask:
      return metaMaskProvider(chainId)

    case MultiWalletProvider.walletConnect:
      return walletConnectProvider(chainId)

    default:
      return metaMaskProvider()
  }
}

const metaMaskProvider = (chainIdParam?: number): MultiWalletService => {
  const listenEvents = () => {
    window.ethereum &&
      (window.ethereum as { on: (eventKey: string, callback: (accounts: string[]) => void) => void }).on(
        'accountsChanged',
        async (accounts: string[]) => {
          if (accounts[0]) {
            accountVar(accounts[0])
          } else {
            clearMultiWalletVars()
          }
        }
      )

    window.ethereum &&
      (window.ethereum as { on: (eventKey: string, callback: (chainIdItem: string) => void) => void }).on(
        'chainChanged',
        async (chainIdItem: string) => {
          chainIdVar(Number.parseInt(chainIdItem, 16))
        }
      )
  }

  return {
    connect: async () => {
      const provider = await detectEthereumProvider()

      if (provider && provider === window.ethereum) {
        try {
          const web3 = new Web3(Web3.givenProvider)

          const account = (
            await (window.ethereum as { request: (args: { method: string }) => Promise<string[]> }).request({
              method: 'eth_requestAccounts'
            })
          )[0]

          const chainId = Number.parseInt(
            await (window.ethereum as { request: (args: { method: string }) => Promise<string> }).request({
              method: 'eth_chainId'
            }),
            16
          )

          if (account && chainId) {
            accountVar(account)
            chainIdVar(chainId)
            setProviderStorage(MultiWalletProvider.metaMask)
            setChainIdStorage(chainId)
            web3Var(web3)

            dataAnalyticsService().trackEvent({
              name: 'Connect Wallet',
              data: {
                wallet: 'metaMask',
                account,
                chainId
              }
            })

            listenEvents()

            if (chainId === 56 || chainIdParam === 56) {
              web3.givenProvider.sendAsync({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: '0x38', // A 0x-prefixed hexadecimal string
                    chainName: 'Binance Smart Chain',
                    nativeCurrency: {
                      name: 'BNB',
                      symbol: 'BNB', // 2-6 characters long
                      decimals: 18
                    },
                    rpcUrls: ['https://bsc-dataseed.binance.org'],
                    blockExplorerUrls: ['https://bscscan.com']
                  }
                ]
              })
            }
          }
        } catch (error) {
          notifyWarning((error as { message: string }).message)
        }
      } else {
        notifyWarning('Please install MetaMask!')
      }
    }
  }
}

const walletConnectProvider = (chainIdParam?: number): MultiWalletService => {
  return {
    connect: async () => {
      try {
        let providerData = {}
        switch (chainIdParam) {
          case 1:
            providerData = {
              infuraId: `${globalConfig.alchemy.ethereum.rpc}/${globalConfig.alchemy.ethereum.key}`,
              chainId: 1
            }
            break
          case 4:
            providerData = {
              infuraId: `${globalConfig.alchemy.rinkeby.rpc}/${globalConfig.alchemy.rinkeby.key}`,
              chainId: 4
            }
            break
          case 5:
            providerData = {
              infuraId: `${globalConfig.alchemy.goerli.rpc}/${globalConfig.alchemy.goerli.key}`,
              chainId: 5
            }
            break
          case 56:
            providerData = {
              chainId: 56,
              rpc: {
                56: `https://bsc-dataseed.binance.org`
              },
              qrcodeModalOptions: {
                mobileLinks: ['trust']
              }
            }
            break
          case 97:
            providerData = {
              chainId: 56,
              rpc: {
                97: `https://data-seed-prebsc-1-s1.binance.org:8545`
              },
              qrcodeModalOptions: {
                mobileLinks: ['trust']
              }
            }
            break
          case 137:
            providerData = {
              infuraId: `${globalConfig.alchemy.polygon.rpc}/${globalConfig.alchemy.polygon.key}`,
              chainId: 137
            }
            break
          case 80001:
            providerData = {
              infuraId: `${globalConfig.alchemy.mumbai.rpc}/${globalConfig.alchemy.mumbai.key}`,
              chainId: 80001
            }
            break
          default:
            break
        }
        const provider = new WalletConnectProvider(providerData)
        await provider.enable()
        const web3 = new Web3(provider as never)
        const account = (await web3.eth.getAccounts())[0]
        const chainId = await web3.eth.getChainId()

        if (account && chainId) {
          accountVar(account)
          chainIdVar(chainId)

          setProviderStorage(MultiWalletProvider.walletConnect)
          setChainIdStorage(chainId)
          web3Var(web3)

          dataAnalyticsService().trackEvent({
            name: 'Connect Wallet',
            data: {
              wallet: 'walletConnect',
              account,
              chainId
            }
          })

          provider.on('accountsChanged', async (accounts: string[]) => {
            if (accounts[0]) {
              accountVar(accounts[0])
            } else {
              clearMultiWalletVars()
            }
          })

          provider.on('chainChanged', async (chainIdItem: number) => {
            chainIdVar(chainIdItem)
          })

          provider.on('disconnect', async () => {
            clearMultiWalletVars()
          })
        }
      } catch (error) {
        notifyError(code[5011])
      }
    }
  }
}

export const initializeWeb3 = (chainId: number, node: boolean) => {
  const config = chainConfig(chainId)

  if (node) {
    return new Web3(new Web3.providers.HttpProvider(config.rpcAddress))
  }

  return web3Var() || new Web3(new Web3.providers.HttpProvider(config.rpcAddress))
}
