import Link from 'next/link'
import styled from 'styled-components'

export default function BannerRewards() {
  return (
    <Container>
      <h4>
        This product will be discontinued soon. Explore our new product that will be launched in 2023 and get early access by completing the
        form.
      </h4>
      <Link href='https://zyuh0c0lz3v.typeform.com/to/SdJkJfPs'>
        <Button>Create Campaign</Button>
      </Link>
    </Container>
  )
}

const { Container, Button } = {
  Container: styled.div`
    position: relative;
    z-index: 9999;
    width: 100%;

    height: auto;
    padding: 16px;
    background: rgb(253, 114, 144);
    background: linear-gradient(90deg, rgba(253, 114, 144, 1) 0%, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 1) 100%);
    text-align: center;
    //display: inline-flex;
    display: none;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);

    @media (max-width: 768px) {
      background: white;
    }

    h4 {
      margin-right: 16px;
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
      color: black;
    }
  `,
  Button: styled.button`
    width: 100%;
    max-width: 160px;
    min-width: 160px;
    height: 30px;
    background: #fd7290;
    color: white;
    font-size: 13px;
    line-height: 16px;
    padding: 4px 16px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);

    &:hover {
      opacity: 0.95;
    }
  `
}
