import { ApolloProvider, useReactiveVar } from '@apollo/client'
import { ConnectWalletModal } from '@components/shared/multi-wallet/ConnectWalletModal'
import { ModalTransaction } from '@components/shared/multi-wallet/transaction/TransactionModal'
import PageTransitionLoader from '@components/shared/PageTransitionLoader'
import { dataAnalyticsService } from '@services/DataAnalyticsService'
import mixpanel from 'mixpanel-browser'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { ThemeProvider } from 'styled-components'
import BannerRewards from '../components/banner/BannerRewards'
import { TheGraphTransactionModal } from '../components/shared/multi-wallet/transaction/TransactionTheGraphModal'
import { backendV1 } from '../graphql/ClientGraphql'
import { ThemeProviderEnum, themeVar } from '../graphql/variables/SharedVariable'
import { MultiWalletProvider } from '../graphql/variables/WalletVariable'
import { multiWalletService } from '../services/MultiWalletService'
import '../styles/globals.css'
import { darkTheme, lightTheme } from '../styles/theme'

function App({ Component, pageProps }: AppProps) {
  const theme = useReactiveVar(themeVar)
  const router = useRouter()
  const [isTransitioning, setIsTransitioning] = useState(false)

  const getCurrentTheme = () => {
    return theme === ThemeProviderEnum.dark ? darkTheme : lightTheme
  }

  useEffect(() => {
    const autoConnect = async () => {
      const provider = window.localStorage.getItem('provider') as MultiWalletProvider | undefined
      const chainId = Number(window.localStorage.getItem('chainId')) as number | undefined
      if (provider && chainId) {
        await multiWalletService(provider, chainId).connect()
      }
    }

    autoConnect()

    mixpanel.init(`${process.env.NEXT_PUBLIC_MIX_PANEL_ID}`, { debug: false })
    TagManager.initialize({ gtmId: 'GTM-KLJZZWB' })
  }, [])

  useEffect(() => {
    const handleStart = () => {
      dataAnalyticsService().trackEvent({
        name: 'Page Transitioning',
        data: {
          route: router.pathname
        }
      })
      setIsTransitioning(true)
    }
    const handleChangeError = () => {
      dataAnalyticsService().trackEvent({
        name: 'Failed to enter page',
        data: {
          route: router.pathname
        }
      })
      setIsTransitioning(false)
    }
    const handleComplete = () => {
      dataAnalyticsService().trackEvent({
        name: 'Page Entered',
        data: {
          route: router.pathname
        }
      })
      setIsTransitioning(false)
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleChangeError)
  }, [router.events, router.pathname])

  return (
    <ApolloProvider client={backendV1}>
      <ThemeProvider theme={getCurrentTheme()}>
        <BannerRewards />
        <Component {...pageProps} />
        <ConnectWalletModal />
        <ModalTransaction />
        <TheGraphTransactionModal />
        <PageTransitionLoader isVisible={isTransitioning} />
      </ThemeProvider>
    </ApolloProvider>
  )
}
export default App
