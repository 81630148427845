import { GetCurrentBlockQueryData, GET_CURRENT_BLOCK_QUERY } from '@graphql/nftfy/deprecated/GetCurrentBlockQuery'
import { genericClient } from '../graphql/ClientGraphql'
import { Transaction, transactionLoadingVar, transactionModalVar, transactionVar } from '../graphql/variables/TransactionVariable'
import { initializeWeb3 } from './MultiWalletService'
import { notifyError } from './NotificationService'

export interface TransactionService {
  confirm(txBlockNumber: number): Promise<void>
  isConfirmedOnBlockchain(txHash: string, chainId: number): Promise<{ isConfirmedOnWeb3: boolean; txBlockNumber: number } | null>
  isTheGraphSynced(blockNumber: number, theGraph: string): Promise<boolean>
}

export function transactionService(): TransactionService {
  return {
    confirm: async (txBlockNumber: number) => {
      const transaction = transactionVar()

      if (transaction) {
        const { hash, type, params } = transaction
        const transactionItem: Transaction = {
          hash,
          type,
          params,
          confirmed: true,
          txBlockNumber
        }
        transactionVar(transactionItem)
        transactionModalVar(false)
        transactionLoadingVar(false)
      } else {
        notifyError('Could not confirm the transaction status')
      }
    },
    async isConfirmedOnBlockchain(txHash: string, chainId: number) {
      try {
        const web3 = initializeWeb3(chainId, true)
        const trx = await web3.eth.getTransaction(txHash)

        if (trx.blockNumber === null) {
          return null
        }

        const currentBlock = await web3.eth.getBlockNumber()
        const web3ConfirmationsCount = 2
        const isConfirmedOnWeb3 = currentBlock - trx.blockNumber > web3ConfirmationsCount
        return { isConfirmedOnWeb3, txBlockNumber: trx.blockNumber }
      } catch (error) {
        return null
      }
    },
    async isTheGraphSynced(blockNumber: number, theGraph: string) {
      const client = genericClient(theGraph)

      if (!client) {
        throw new Error('TransactionService: Client not defined!')
      }

      const { data, error } = await client.query<GetCurrentBlockQueryData>({
        query: GET_CURRENT_BLOCK_QUERY
      })

      if (error) {
        throw new Error('TransactionService: error query "GET_CURRENT_BLOCK_QUERY"!')
      }

      if (!data) {
        return false
      }

      return data._meta.block.number >= blockNumber
    }
  }
}
