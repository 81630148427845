import styled from 'styled-components'
import CardLoader from '../design-system/CardLoader'

const PageTransitionLoader = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <S.Container className={isVisible ? 'show' : ''}>
      <CardLoader />
    </S.Container>
  )
}

export default PageTransitionLoader

const S = {
  Container: styled.div`
    height: 100vh;
    z-index: 9999;
    width: 100%;
    display: none;
    justify-content: center;
    position: fixed;
    align-items: center;
    top: 0;
    transition: visibility 0.25s ease-in;
    background-color: ${props => `${props.theme.colors.constants.black}80`};

    &.show {
      display: flex;
    }
  `
}
