import { makeVar } from '@apollo/client'
import { FractionalizeFormData } from '@appTypes/FunnelTypes'
import { GenericModal, GenericModalConfirmation } from '@appTypes/UtilTypes'
import { WalletERC20Share } from '@appTypes/WalletTypes'
import { SupportedNetworks } from '@config'
import { NftData } from '../nftfy/backend/shared/erc721/nftsByWallet'

// eslint-disable-next-line no-shadow
export enum ThemeProviderEnum {
  light = 'light',
  dark = 'dark'
}

// eslint-disable-next-line no-shadow
export enum NetworkFilterEnum {
  eth = 1,
  bsc = 56,
  polygon = 137,
  mumbai = 80001,
  kovan = 42
}

export enum TokenTypeEnum {
  collection = 'collection',
  listed = 'listed',
  fractions = 'fractions',
  pools = 'pools',
  privatePools = 'privatePools'
}

export const networkFilterVar = makeVar<NetworkFilterEnum[]>([NetworkFilterEnum.eth])

export const tokenTypeFilterVar = makeVar<TokenTypeEnum[]>([TokenTypeEnum.collection])

export const themeVar = makeVar<ThemeProviderEnum>(ThemeProviderEnum.dark)

export const getTheme = () => {
  const theme = window.localStorage.getItem('theme') || undefined
  setTheme(theme === ThemeProviderEnum.light ? ThemeProviderEnum.light : ThemeProviderEnum.dark)
}

export const setTheme = (theme: ThemeProviderEnum) => {
  themeVar(theme)
  theme ? window.localStorage.setItem('theme', theme) : window.localStorage.removeItem('theme')
}

export const openMobileMenuVar = makeVar(false)
export const openWrongNetworkModalVar = makeVar<SupportedNetworks | null>(null)

export const openChangeNetworkModalVar = makeVar<{
  openModal: boolean
  nft?: NftData
}>({ openModal: false })

export const claimModalVar = makeVar<WalletERC20Share | undefined>(undefined)

export const genericModalVar = makeVar<GenericModal | undefined>(undefined)

export const genericModalConfirmationVar = makeVar<GenericModalConfirmation | undefined>(undefined)

export const isSelectedNftFinishedVar = makeVar(false)

export const vaultInfoFormVar = makeVar<FractionalizeFormData | undefined>(undefined)

export const vaultInfoProductVar = makeVar<string>('')
