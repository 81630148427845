import { message } from 'antd'

export const notifySuccess = (text: string) => {
  message.success(text)
}
export const notifyWarning = (text: string) => {
  message.warning(text)
}
/**
 * @deprecated Throw error instead
 */
export const notifyError = (text: string, err?: unknown, silent?: boolean) => {
  if (!silent) {
    message.error(text)
  }

  if (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}
