import binanceTokenIcon from '../public/assets/tokens/bnb.svg'
import ethTokenIcon from '../public/assets/tokens/ethereum.svg'
import polygonTokenIcon from '../public/assets/tokens/polygon.svg'
import { AssetERC20 } from './types/WalletTypes'

if (!process.env.NEXT_PUBLIC_BASE_URL) {
  throw new Error('NEXT_PUBLIC_BASE_URL is not set')
}

if (!process.env.NEXT_PUBLIC_ALCHEMY_MAINNET_KEY) {
  throw new Error('NEXT_PUBLIC_ALCHEMY_MAINNET_KEY is not set')
}

if (!process.env.NEXT_PUBLIC_ALCHEMY_RINKEBY_KEY) {
  throw new Error('NEXT_PUBLIC_ALCHEMY_RINKEBY_KEY is not set')
}

if (!process.env.NEXT_PUBLIC_ALCHEMY_GOERLI_KEY) {
  throw new Error('NEXT_PUBLIC_ALCHEMY_GOERLI_KEY is not set')
}

if (!process.env.NEXT_PUBLIC_ALCHEMY_POLYGON_KEY) {
  throw new Error('NEXT_PUBLIC_ALCHEMY_POLYGON_KEY is not set')
}

if (!process.env.NEXT_PUBLIC_ALCHEMY_MUMBAI_KEY) {
  throw new Error('NEXT_PUBLIC_ALCHEMY_MUMBAI_KEY is not set')
}

if (!process.env.NEXT_PUBLIC_OPENSEA_API_KEY) {
  throw new Error('NEXT_PUBLIC_OPENSEA_API_KEY is not set')
}

if (!process.env.NEXT_PUBLIC_BACKEND_BUCKET_URL) {
  throw new Error('NEXT_PUBLIC_BACKEND_BUCKET_URL is not set')
}

if (!process.env.NEXT_PUBLIC_BACKEND_V1) {
  throw new Error('NEXT_PUBLIC_BACKEND_V1 is not set')
}

if (!process.env.NEXT_PUBLIC_BACKEND_V2) {
  throw new Error('NEXT_PUBLIC_BACKEND_V2 is not set')
}

if (!process.env.NEXT_PUBLIC_TOKEN_ROCKPOOL) {
  throw new Error('NEXT_PUBLIC_TOKEN_ROCKPOOL is not set')
}

export enum SupportedNetworks {
  ethereum = 1,
  rinkeby = 4,
  goerli = 5,
  'binance_smart_chain' = 56,
  'binance_testnet' = 97,
  polygon = 137,
  mumbai = 80001
}

export enum SupportedRockpoolNetworks {
  ethereum = 1,
  rinkeby = 4,
  goerli = 5
}

export enum NetworksName {
  'ethereum' = 'ethereum',
  'rinkeby' = 'rinkeby',
  'goerli' = 'goerli',
  'binance_smart_chain' = 'binance_smart_chain',
  'binance_testnet' = 'binance_testnet',
  'polygon' = 'polygon',
  'mumbai' = 'mumbai'
}

export enum NetworksId {
  ethereum = 1,
  rinkeby = 4,
  goerli = 5,
  binance_smart_chain = 56,
  binance_testnet = 97,
  polygon = 137,
  mumbai = 80001
}

export const allowedChains = [1, 4, 5, 56, 97, 137, 80001]
export const blacklist = [
  '137_0xb58dd0e6e06d909b261fc3903d8a982d4a47f9a5',
  '137_0x08d3f3833525755a52068a7d2ea1f04298175174',
  '137_0x3e71efa9ac07a6a1f1b0499027fc5c6774728b7d',
  '137_0x4484b54610367fd44cca50468aa1c5b1aa592af9',
  '137_0xd0631745e503af70ce00209a8875f594f7652dbf',
  '137_0x0b5f8d0b988742086c9d198ad664d07fb01a6ca8',
  '137_0xf9f523d2445b6a69b4629e752d470c8b1a040902',
  '137_0x72ca63d2f2ebff7418b2b543f7eae7cc23b61fff',
  '137_0x79e16aa32bbe985d0ce77a95ece81cd0320b45ab',
  '137_0x046ff52897597e04cf69432f7f8b20fa411eded1',
  '137_0x8e3302a8449b506dca16299231486a46a207900f',
  '137_0x02f960851eaee36081ce7c085474a7a6118db3fc',
  '137_0x3e6de2264e7eb5b442ab812ca4c2eed655d3c174',
  '137_0x7cf5322918f05e3db8ac71860e228fae403ca7b4',
  '137_0x0b5f8d0b988742086c9d198ad664d07fb01a6ca8',
  '137_0xf38eb9e060fc7104908db80968b3e2a7a226b07f',
  '137_37',
  '137_34'
]
export const openseaAllowedChains = [SupportedNetworks.ethereum, SupportedNetworks.rinkeby, SupportedNetworks.goerli]
interface FarmConfig {
  rightChainId: number
  name: string
  contract: string
  contractErc20: string
  contractUrl: string
  contractErc20Url: string
  contractPosition: number
  blockTime: number
  lpType: 'balancer-v1' | 'uniswap-v2'
}
export interface GlobalConfigV2 {
  baseUrl: string
  alchemy: {
    ethereum: {
      rpc: string
      key: string
    }
    rinkeby: {
      rpc: string
      key: string
    }
    goerli: {
      rpc: string
      key: string
    }
    polygon: {
      rpc: string
      key: string
    }
    mumbai: {
      rpc: string
      key: string
    }
  }
  openSea: {
    key: string
  }
  paginationLimit: number
  nftfyBackend: {
    bucketUrl: string
    theGraphBaseUrl: string
    v1: string
    v2: string
  }
  farms: {
    busdNftfy: FarmConfig
    bnbNftfy: FarmConfig
    wethNftfy: FarmConfig
    daiNftfy: FarmConfig
    ethNftfy: FarmConfig
  }
  zeroXBaseUrl: string
  openSeaAssetsUrl: string
  openSeaTestNetAssetsUrl: string
  openSeaApiUrl: string
  balancerEthAddress: string
  cryptokitties: {
    apiUrl: string
    xApiToken: string
    contractAddress: string
    symbol: string
  }
  balancerPoolsUrl?: string
  bridge: {
    panel: string
    trustedBridge: string
    networks: {
      bsc: {
        name: string
        chainId: number
        address: string
        operator: string
        vault: string
        multisig: string
      }
      ethereum: {
        name: string
        chainId: number
        address: string
        operator: string
        vault: string
        multisig: string
      }
    }
  }
  betaTokens: {
    rockpool: string
  }
}
export const globalConfig: GlobalConfigV2 = {
  baseUrl: process.env.NEXT_PUBLIC_BASE_URL,
  alchemy: {
    ethereum: {
      rpc: 'https://eth-mainnet.g.alchemy.com/v2',
      key: process.env.NEXT_PUBLIC_ALCHEMY_MAINNET_KEY
    },
    rinkeby: {
      rpc: 'https://eth-rinkeby.g.alchemy.com/v2',
      key: process.env.NEXT_PUBLIC_ALCHEMY_GOERLI_KEY
    },
    goerli: {
      rpc: 'https://eth-goerli.g.alchemy.com/v2',
      key: process.env.NEXT_PUBLIC_ALCHEMY_GOERLI_KEY
    },
    polygon: {
      rpc: 'https://polygon-mainnet.g.alchemy.com/v2',
      key: process.env.NEXT_PUBLIC_ALCHEMY_POLYGON_KEY
    },
    mumbai: {
      rpc: 'https://polygon-mumbai.g.alchemy.com/v2',
      key: process.env.NEXT_PUBLIC_ALCHEMY_MUMBAI_KEY
    }
  },
  openSea: {
    key: process.env.NEXT_PUBLIC_OPENSEA_API_KEY
  },
  paginationLimit: 20,
  nftfyBackend: {
    bucketUrl: process.env.NEXT_PUBLIC_BACKEND_BUCKET_URL,
    theGraphBaseUrl: 'https://api.thegraph.com/subgraphs/id',
    v1: process.env.NEXT_PUBLIC_BACKEND_V1,
    v2: process.env.NEXT_PUBLIC_BACKEND_V2
  },
  cryptokitties: {
    apiUrl: 'https://public.api.cryptokitties.co/v1',
    xApiToken: 'y21Z9U63U7YhJgaofcsgc_Plm6w5KDkMvdTICcZ19AE',
    contractAddress: '0x06012c8cf97BEaD5deAe237070F9587f8E7A266d',
    symbol: 'CK'
  },
  farms: {
    busdNftfy: {
      rightChainId: 56,
      name: 'BUSD-NFTFY',
      contract: '0x8780421e5D37C3E2dFcb7Db3FB4e9e4A6D2aB1A3',
      contractErc20: '0xFa901CEF85f2C3A3F4FcBFe34904b8Da0f5Fc04e',
      contractUrl: 'https://bscscan.com/address/0x8780421e5D37C3E2dFcb7Db3FB4e9e4A6D2aB1A3#readContract',
      contractErc20Url:
        'https://exchange.pancakeswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0xbf6ff49ffd3d104302ef0ab0f10f5a84324c091c',
      contractPosition: 0,
      blockTime: 3,
      lpType: 'uniswap-v2'
    },
    bnbNftfy: {
      rightChainId: 56,
      name: 'BNB-NFTFY',
      contract: '0x8780421e5D37C3E2dFcb7Db3FB4e9e4A6D2aB1A3',
      contractErc20: '0xE4bA58268a64E93Fc29295308178816e774879f0',
      contractUrl: 'https://bscscan.com/address/0x8780421e5D37C3E2dFcb7Db3FB4e9e4A6D2aB1A3#readContract',
      contractErc20Url: 'https://exchange.pancakeswap.finance/#/add/BNB/0xbf6ff49ffd3d104302ef0ab0f10f5a84324c091c',
      contractPosition: 1,
      blockTime: 3,
      lpType: 'uniswap-v2'
    },
    wethNftfy: {
      rightChainId: 1,
      name: 'WETH-NFTFY',
      contract: '0x9Df6e60c1fB5266f453E683d9C9487DBFd720ffB',
      contractErc20: '0xd618f2990F69E73ac48115E79c029705F41aa994',
      contractUrl: 'https://etherscan.io/address/0x9Df6e60c1fB5266f453E683d9C9487DBFd720ffB#readContract',
      contractErc20Url: 'https://pools.balancer.exchange/#/pool/0xd618f2990f69e73ac48115e79c029705f41aa994/',
      contractPosition: 0,
      blockTime: 13,
      lpType: 'balancer-v1'
    },
    daiNftfy: {
      rightChainId: 1,
      name: 'DAI-NFTFY',
      contract: '0x9Df6e60c1fB5266f453E683d9C9487DBFd720ffB',
      contractErc20: '0x63E4c1d480a373F2d66c68c1A1a406Bd7C60A73e',
      contractUrl: 'https://etherscan.io/address/0x9Df6e60c1fB5266f453E683d9C9487DBFd720ffB#readContract',
      contractErc20Url: 'https://pools.balancer.exchange/#/pool/0x63e4c1d480a373f2d66c68c1a1a406bd7c60a73e/',
      contractPosition: 1,
      blockTime: 13,
      lpType: 'balancer-v1'
    },
    ethNftfy: {
      rightChainId: 1,
      name: 'ETH-NFTFY - Uniswap',
      contract: '0x9Df6e60c1fB5266f453E683d9C9487DBFd720ffB',
      contractErc20: '0x2729dBDe598D54cE4FA5cEe1098306438Bd4ed90',
      contractUrl: 'https://etherscan.io/address/0x2729dBDe598D54cE4FA5cEe1098306438Bd4ed90',
      contractErc20Url: 'https://app.uniswap.org/#/add/v2/ETH/0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
      contractPosition: 2,
      blockTime: 13,
      lpType: 'uniswap-v2'
    }
  },
  zeroXBaseUrl: 'https://api.0x.org',
  openSeaAssetsUrl: 'https://opensea.io/assets',
  openSeaTestNetAssetsUrl: 'https://testnets.opensea.io/assets',
  openSeaApiUrl: 'https://api.opensea.io/api/v1',
  balancerEthAddress: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  balancerPoolsUrl: 'https://pools.balancer.exchange/',
  bridge: {
    panel: '0x9A64c1e500ED64CB012290A3868F36D33bb85F7e',
    trustedBridge: '0xFD332f27B72d2730BF0B34A7dD3e51c9FB902cd0',
    networks: {
      ethereum: {
        chainId: 1,
        name: 'Ethereum Network',
        address: '0x41f455283d6230A4eE83aE7216FfFb527bBd862A',
        operator: '0xF9a7c456341fAFc861909c2FF17C8c6366234f2F',
        vault: '0x09567066a42c087218053525Fb2fB19A0bA78A6f',
        multisig: '0xc718E5a5b06ce7FEd722B128C0C0Eb9c5c902D92'
      },
      bsc: {
        chainId: 56,
        name: 'Binance Smart Chain Network (BEP20)',
        address: '0x41f455283d6230A4eE83aE7216FfFb527bBd862A',
        operator: '0xF9a7c456341fAFc861909c2FF17C8c6366234f2F',
        vault: '0x09567066a42c087218053525Fb2fB19A0bA78A6f',
        multisig: '0x4e9cA8ca6A113FC3Db72677aa04C8DE028618377'
      }
    }
  },
  betaTokens: {
    rockpool: process.env.NEXT_PUBLIC_TOKEN_ROCKPOOL
  }
}
export interface RockpoolConfig {
  theGraph: string
  openCollectivePurchaseContract: string
  externalAcquirerContract: string
}

interface ChainConfigV2 {
  chainId: number
  erc20List: AssetERC20[]
  name: NetworksName
  fantasyName: string
  stablecoinAddress: string
  networkTokenAddress: string
  networkTokenIcon: string
  networkTokenDecimals: number
  networkTokenSymbol: string
  fracionalizerV1: {
    contract: string | null
    theGraph: string | null
  }
  fracionalizerV1_2: {
    contractFixedPrice: string
    contractAuction: string | null
    theGraph: string
  }
  collectiveBuy: {
    contract: string | null
    theGraph: string
  }
  marketplace: {
    theGraph: string
  }
  nft: {
    theGraph: string | null
  }
  box: {
    contract: string
    contractAux: string
    theGraph: string
  }
  nftfyToken: {
    address: string
    decimals: number
  }
  p2p: {
    theGraph: string
    contract: string
  }
  marketplaceBlacklist: string[]
  assets: string[]
  rpcAddress: string
  networkScanAddress: string
  nftfyAuctionAddress: string
  mint: {
    minterAddress: string
    defaultCollectionAddress: string
  }
  pinataBaseUrl: string
  rockpool?: {
    theGraph: string
    externalAcquirerContract: string
    openCollectivePurchaseContract: string
  }
}

const chainsConfig: ChainConfigV2[] = [
  {
    chainId: 1,
    networkScanAddress: 'https://etherscan.io/',
    box: {
      contract: '0xFc44f66D5D689cD9108c6577533E9572f53a50Bc',
      contractAux: '0xbbFfeF9d52F252CA60feFBcB73d7145dECee1804',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmXBX2oBntKD9oF2fiR4EcjCctPdnGH7zL1bhC27sLauCS'
    },
    name: NetworksName.ethereum,
    fantasyName: 'Ethereum',
    networkTokenAddress: '0x0000000000000000000000000000000000000000',
    networkTokenIcon: ethTokenIcon,
    networkTokenDecimals: 18,
    networkTokenSymbol: 'ETH',
    nft: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmTb4nBjfoUnGZNatsFQK5m2xwnW11qQ9ojyx2QfWNsoDV'
    },
    fracionalizerV1: {
      contract: '0x727638740980aA0aA0B346d02dd91120Eaac75ed',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmcqHCqjnRbWp4oMPWtrCq9MLHXjeJ3z1kbuy2y4EPFhG3'
    },
    fracionalizerV1_2: {
      contractFixedPrice: '0x8279BE8f740DBeDB05C0Ce165447E8bc1457a137',
      contractAuction: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmNoCHwGkF1TgvkQWsW5ok7QKmMxPU4BdDGTwBaKTV6J4i'
    },
    collectiveBuy: {
      contract: '0x1e95B705Dbbb3E1128445aFc06D9dA401552FDCb',
      theGraph: 'https://api.thegraph.com/subgraphs/id/Qmb6yxu8eRDfikmgmzso9s3WKCrBYxs1KCvNkjUPY9wYkj'
    },
    marketplace: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmaZWACCH63FenSuDkiTLSRsBuGh3bZgvALVMRtpPHMEM4'
    },
    p2p: {
      contract: '0x91FE09bB4D060abc2FD1460f87D19c4c9410448e',
      theGraph: 'https://api.thegraph.com/subgraphs/id/Qmdm8v5BfQRzoz5mPr8FrdDosMsYS5kh8ctfMjK3cNqZ7W'
    },
    stablecoinAddress: '0x6b175474e89094c44da98b954eedeac495271d0f',
    marketplaceBlacklist: [
      '0xe0d522a32eb8f8b73702039c9e5a1285e3862b28',
      '0x38d78c1b49ab10ba162990eedb72824bb8163b86',
      '0x0622c046f185bdaec9d615fe458526423c1712fc',
      '0x064e587292ec7228664f01c218fb7c28a98b2aaf'
    ],
    nftfyToken: {
      address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
      decimals: 18
    },
    rpcAddress: `${globalConfig.alchemy.ethereum.rpc}/${globalConfig.alchemy.ethereum.key}`,
    erc20List: [
      {
        id: '1',
        name: 'Nftfy',
        symbol: 'NFTFY',
        address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      },
      {
        id: '2',
        name: 'Ethereum',
        symbol: 'ETH',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      },
      {
        id: '3',
        name: 'USD Coin',
        symbol: 'USDC',
        address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        balance: '0',
        decimals: 6,
        imageUrl: ''
      },
      {
        id: '4',
        name: 'DAI Stablecoin',
        symbol: 'DAI',
        address: '0x6b175474e89094c44da98b954eedeac495271d0f',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      }
    ],
    nftfyAuctionAddress: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
    mint: {
      minterAddress: '0x62cF87B0E441e6E3A1634304AbA6332F3Fd6464F',
      defaultCollectionAddress: '0x9c8a2b35B268bf2AA69f5fc105514e34daF3cEBb'
    },
    pinataBaseUrl: 'https://nftfypinningservice.azurewebsites.net/api/uploadMedia',
    rockpool: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmWnZpfoTFk2dyuGU4BRqxtxeSVJ4Ny9R5spoeH8daMq2d',
      openCollectivePurchaseContract: '0x1afcCa7a8656A1BCC5A3827B97A183C4Cd1740c2',
      externalAcquirerContract: '0x8eD69629B8fA69eEf1b019a3a427C08DC24Dd35f'
    },
    assets: [
      '0x9a48bd0ec040ea4f1d3147c025cd4076a2e71e3e',
      '0x49f2beff98ce62999792ec98d0ee4ad790e7786f',
      '0x78f225869c08d478c34e5f645d07a87d3fe8eb78',
      '0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd',
      '0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c',
      '0xad32a8e6220741182940c5abf610bde99e737b2d',
      '0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b',
      '0x5bc25f649fc4e26069ddf4cf4010f9f706c23831',
      '0x20945ca1df56d237fd40036d47e866c7dccd2114',
      '0x705c71b262c511b66baa4791cc2be61b971bd784',
      '0x3d7753c4526f8657e383a46dc41ec97414941a80',
      '0x261efcdd24cea98652b9700800a13dfbca4103ff',
      '0x8d1ce361eb68e9e05573443c407d4a3bed23b033',
      '0x51a2310b37ead1d8f8fe01b89b4cd68fa58992d0',
      '0xe59064a8185ed1fca1d17999621efedfab4425c9',
      '0xa0afaa285ce85974c3c881256cb7f225e3a1178a',
      '0x6c2cf58e468b64b604407c6ea89c5868ed13c252',
      '0x80dc468671316e50d4e9023d3db38d3105c1c146',
      '0x704de5696df237c5b9ba0de9ba7e0c63da8ea0df',
      '0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713',
      '0x7f1f2d3dfa99678675ece1c243d3f7bc3746db5d',
      '0x30cf203b48edaa42c3b4918e955fed26cd012a3f',
      '0x34a01c0a95b0592cc818cd846c3cf285d6c85a31',
      '0x64010f6ba757715d8f12d8317004425d73ca5a81',
      '0xe4f726adc8e89c6a6017f01eada77865db22da14',
      '0xc69a0ae4961b8bac945d854e1057117e575fe005',
      '0xac2c198f3103ee19bab07530f2bc2c2ae88f2cb5',
      '0xffffffff2ba8f66d4e51811c5190992176930278',
      '0xf2092e49701ddefdec82d518d8d34345302d1b43',
      '0x6fcb6408499a7c0f242e32d77eb51ffa1dd28a7e',
      '0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd',
      '0x875773784af8135ea0ef43b5a374aad105c5d39e',
      '0x41a08648c3766f9f9d85598ff102a08f4ef84f84',
      '0x859e4d219e83204a2ea389dac11048cc880b6aa8',
      '0xf5581dfefd8fb0e4aec526be659cfab1f8c781da',
      '0x8cacf4c0f660efdc3fd2e2266e86a9f57f794198',
      '0x69af81e73a73b40adf4f3d4223cd9b1ece623074',
      '0x9dc54c3633ba36e9c0205ee27584eb589ea96421',
      '0x59e9261255644c411afdd00bd89162d09d862e38',
      '0x31c8eacbffdd875c74b94b077895bd78cf1e64a3',
      '0x750dd34fb165be682fae445793ab9ab9729cdaa3',
      '0x67b6d479c7bb412c54e03dca8e1bc6740ce6b99c',
      '0x2ed30c582c7faf644f291db9eecdc5641057233e',
      '0x06745bee20ad9cc7dfb6f40b66504397235f547c',
      '0xfb5453340c03db5ade474b27e68b6a9c6b2823eb',
      '0x75a38f0d99eb3e4b18246febebb77b16cf95d754',
      '0x08d8fa18af801224719b90327670ab1145e24ba6',
      '0xf938424f7210f31df2aee3011291b658f872e91e',
      '0x945facb997494cc2570096c74b5f66a3507330a1',
      '0x544c42fbb96b39b21df61cf322b5edc285ee7429',
      '0xcbfef8fdd706cde6f208460f2bf39aa9c785f05d',
      '0xa06849c61e0919785661acb7fe3bcbd4500f6c2e',
      '0x27cf76c51309929c6c09659b43b026c1ab649516',
      '0xf675243988d10f9de458df019a501b64245e4eda',
      '0xbc4171f45ef0ef66e76f979df021a34b46dcc81d',
      '0xb8e57e2eff4b9c1ee413c7b24b1f52d83e16488c',
      '0xe4cfe9eaa8cdb0942a80b7bc68fd8ab0f6d44903',
      '0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429',
      '0x5a04a6246f2ce98230f5409bc11ccb0183929d02',
      '0x29cbd0510eec0327992cd6006e63f9fa8e7f33b7',
      '0x61b6325d72aa40aea68ef8a1e09055697b5b8d83',
      '0x767fe9edc9e0df98e07454847909b5e959d7ca0e',
      '0xf657666c7823c68dcca168c4c1c9a28d9d04ec29'
    ]
  },
  {
    chainId: 4,
    name: NetworksName.rinkeby,
    fantasyName: 'Rinkeby',
    networkTokenSymbol: 'ETH',
    stablecoinAddress: '0x6b175474e89094c44da98b954eedeac495271d0f',
    networkTokenAddress: '0x0000000000000000000000000000000000000000',
    networkTokenIcon: ethTokenIcon,
    networkTokenDecimals: 18,
    networkScanAddress: 'https://rinkeby.etherscan.io/',
    assets: [],
    box: {
      contract: '0xFc44f66D5D689cD9108c6577533E9572f53a50Bc',
      contractAux: '0xbbFfeF9d52F252CA60feFBcB73d7145dECee1804',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmTvKuBwTkEkgJBVoBemKxFDwvKCvVSAjbVyDNeXdUueTM/graphql'
    },
    nft: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmcA52Nwk1jrQF1v6tP3pHhXWVeAxxh4upPt5FD9t6tBQU'
    },
    p2p: {
      // Contract address not found
      contract: '0x91FE09bB4D060abc2FD1460f87D19c4c9410448e',
      theGraph: 'https://api.thegraph.com/subgraphs/id/Qmam5Hrr2HTgGuHNzL6peE7nLJWXFybvLFRd1ajGGTB3xS'
    },
    fracionalizerV1: {
      contract: '0x727638740980aA0aA0B346d02dd91120Eaac75ed',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmQpRXNDCscmRoJ45yMjXotJvHG7fbjrzixBUSXyf9AgYE'
    },
    fracionalizerV1_2: {
      contractFixedPrice: '0x8279BE8f740DBeDB05C0Ce165447E8bc1457a137',
      contractAuction: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmXzFm9QH8bdB8yG6cHYYAhbMnRbjyawaYVQ6ejufmNg45'
    },
    collectiveBuy: {
      contract: '0x1e95B705Dbbb3E1128445aFc06D9dA401552FDCb',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmNYUoF97oJpKV3uekehfLy3B9MuhDdwwsa2fW6yxhKW8m'
    },
    marketplace: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmVZ3BtAnhWoXphsMtewxgCtSbRMtAb1hC1XSvpaM7p5B2'
    },
    marketplaceBlacklist: [],
    nftfyToken: {
      address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
      decimals: 18
    },
    rpcAddress: `${globalConfig.alchemy.rinkeby.rpc}/${globalConfig.alchemy.rinkeby.key}`,
    erc20List: [
      {
        id: '1',
        name: 'Nftfy',
        symbol: 'NFTFY',
        address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      },
      {
        id: '2',
        name: 'Ethereum',
        symbol: 'ETH',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      },
      {
        id: '3',
        name: 'USD Coin',
        symbol: 'USDC',
        address: '0x2F375e94FC336Cdec2Dc0cCB5277FE59CBf1cAe5',
        balance: '0',
        decimals: 6,
        imageUrl: ''
      },
      {
        id: '4',
        name: 'DAI Stablecoin',
        symbol: 'DAI',
        address: '0x1528f3fcc26d13f7079325fb78d9442607781c8c',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      }
    ],
    nftfyAuctionAddress: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
    mint: {
      minterAddress: '0x62cF87B0E441e6E3A1634304AbA6332F3Fd6464F',
      defaultCollectionAddress: '0x9c8a2b35B268bf2AA69f5fc105514e34daF3cEBb'
    },
    pinataBaseUrl: 'https://nftfypinningservice.azurewebsites.net/api/uploadMedia',
    rockpool: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/Qmf4PEbhgQCshvtFS7wJtbgk75t3Vc5oCWaHhMVqKt47Xf',
      openCollectivePurchaseContract: '0x1afcCa7a8656A1BCC5A3827B97A183C4Cd1740c2',
      externalAcquirerContract: '0x8eD69629B8fA69eEf1b019a3a427C08DC24Dd35f'
    }
  },
  {
    chainId: 5,
    name: NetworksName.goerli,
    fantasyName: 'Goerli',
    networkTokenSymbol: 'ETH',
    stablecoinAddress: '0x6b175474e89094c44da98b954eedeac495271d0f',
    networkTokenAddress: '0x0000000000000000000000000000000000000000',
    networkTokenIcon: ethTokenIcon,
    networkTokenDecimals: 18,
    networkScanAddress: 'https://goerli.etherscan.io/',
    assets: [],
    box: {
      contract: '0xFc44f66D5D689cD9108c6577533E9572f53a50Bc',
      contractAux: '0xbbFfeF9d52F252CA60feFBcB73d7145dECee1804',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmUfZuBCHmdtNQg59Mv2yyerr4XuZPF45uAArU9HQDdLqb'
    },
    nft: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmZW489aMiH7hFn1JYoQF7eneGyoN8up72zUs7XZ9MEPQb'
    },
    p2p: {
      contract: '0x91FE09bB4D060abc2FD1460f87D19c4c9410448e',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmSEncg6YWLTXGJV1rUCpshXzZxd8UnYajQo6fWkymverq'
    },
    fracionalizerV1: {
      contract: '0x727638740980aA0aA0B346d02dd91120Eaac75ed',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmY75dFUNYxWrHk7WENFPPpovt528vRDLW4kVh5ETNTk4N'
    },
    fracionalizerV1_2: {
      contractFixedPrice: '0x8279BE8f740DBeDB05C0Ce165447E8bc1457a137',
      contractAuction: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmbupJz1HWzUiHw9kpWEy6Te63ExyXL5zSu9PKoFE2AUad'
    },
    collectiveBuy: {
      contract: '0x1e95B705Dbbb3E1128445aFc06D9dA401552FDCb',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmVgdCyLe9Zifgy3xZwdVXzvMA2dQnqMxHqaSjT3kpzwcC'
    },
    marketplace: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmbbtD91BVHPmLzDvy7Uqv7KSLHX8jQTfnZwMHAjzKiDcN'
    },
    rockpool: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmcCdhq4ADYQApYW1YcaYv5u5HoPwNxxNGcihFFLuM466y',
      openCollectivePurchaseContract: '0x1afcCa7a8656A1BCC5A3827B97A183C4Cd1740c2',
      externalAcquirerContract: '0x8eD69629B8fA69eEf1b019a3a427C08DC24Dd35f'
    },
    marketplaceBlacklist: [],
    nftfyToken: {
      address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
      decimals: 18
    },
    rpcAddress: `${globalConfig.alchemy.goerli.rpc}/${globalConfig.alchemy.goerli.key}`,
    erc20List: [
      {
        id: '1',
        name: 'Nftfy',
        symbol: 'NFTFY',
        address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      },
      {
        id: '2',
        name: 'Ethereum',
        symbol: 'ETH',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      },
      {
        id: '4',
        name: 'DAI Stablecoin',
        symbol: 'DAI',
        address: '0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      }
    ],
    nftfyAuctionAddress: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
    mint: {
      minterAddress: '0x62cF87B0E441e6E3A1634304AbA6332F3Fd6464F',
      defaultCollectionAddress: '0x9c8a2b35B268bf2AA69f5fc105514e34daF3cEBb'
    },
    pinataBaseUrl: '#'
  },
  {
    chainId: 137,
    erc20List: [
      {
        id: '1',
        name: 'Matic Token',
        symbol: 'MATIC',
        address: '0x0000000000000000000000000000000000001010',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      }
    ],
    name: NetworksName.polygon,
    fantasyName: 'Polygon',
    stablecoinAddress: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    networkTokenAddress: '0x0000000000000000000000000000000000000000',
    networkTokenIcon: polygonTokenIcon,
    networkTokenDecimals: 18,
    networkTokenSymbol: 'MATIC',
    fracionalizerV1: {
      contract: '0x727638740980aA0aA0B346d02dd91120Eaac75ed',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmNbLpHSs1tgWqayG1jHwFVbnywQc51mqPSnr9SqP8PVBS'
    },
    fracionalizerV1_2: {
      contractFixedPrice: '0x8279BE8f740DBeDB05C0Ce165447E8bc1457a137',
      contractAuction: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmVqLEUe5FwK1L2sx25qMCffXLQWfvejFBx9NnCKU9ZuPM'
    },
    collectiveBuy: {
      contract: '0x1e95B705Dbbb3E1128445aFc06D9dA401552FDCb',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmRAjiNSKrbsMNraWvoohqEMj1LrzXyzJuzLj7WU86kmTd'
    },
    marketplace: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmaiM6aa4uHnado2sKr8mBNiCKP2Akn2VUUi1wuHLDF7Ez'
    },
    box: {
      contract: '0xFc44f66D5D689cD9108c6577533E9572f53a50Bc',
      contractAux: '0xbbFfeF9d52F252CA60feFBcB73d7145dECee1804',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmXKEssWZDbeueoLNqhfhG9RjgsQPmtfA4sHUr5a1rjYWr'
    },
    nft: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmZB8hkUbPg717UKcvhwQHCoDxVM9Zz7H2wMcU1jDRgqGS'
    },
    nftfyToken: {
      address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
      decimals: 18
    },
    p2p: {
      theGraph: '',
      contract: ''
    },
    marketplaceBlacklist: [],
    assets: [],
    rpcAddress: `${globalConfig.alchemy.polygon.rpc}/${globalConfig.alchemy.polygon.key}`,
    networkScanAddress: 'https://polygonscan.com/',
    nftfyAuctionAddress: '',
    mint: {
      minterAddress: '',
      defaultCollectionAddress: ''
    },
    pinataBaseUrl: 'https://nftfypinningservice.azurewebsites.net/api/uploadMedia'
  },
  {
    chainId: 80001,
    erc20List: [
      {
        id: '1',
        name: 'Matic Token',
        symbol: 'MATIC',
        address: '0x0000000000000000000000000000000000001010',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      }
    ],
    name: NetworksName.mumbai,
    fantasyName: 'Mumbai',
    stablecoinAddress: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    networkTokenAddress: '0x0000000000000000000000000000000000000000',
    networkTokenIcon: polygonTokenIcon,
    networkTokenDecimals: 18,
    networkTokenSymbol: 'MATIC',
    fracionalizerV1: {
      contract: '0x727638740980aA0aA0B346d02dd91120Eaac75ed',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmUzsCCHu5g8f9N18PGdZJnPJHMnF5JefH4vJ5WBc1uv3R'
    },
    fracionalizerV1_2: {
      contractFixedPrice: '0x8279BE8f740DBeDB05C0Ce165447E8bc1457a137',
      contractAuction: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmWiYpeLgtn7qdTZZCpdHj16Q9XmLi2Ub1tNXiCG4jFTXW'
    },
    collectiveBuy: {
      contract: '0x1e95B705Dbbb3E1128445aFc06D9dA401552FDCb',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmTLWN4dZdtBQ4RMnUvmxEPSx5B88dJgVW6jeZMnFKWEEN'
    },
    marketplace: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmWC9ik5VmwZdvRpvq3v8cY5715aANPEiGMcHEjXtTUdLk'
    },
    box: {
      contract: '0xFc44f66D5D689cD9108c6577533E9572f53a50Bc',
      contractAux: '0xbbFfeF9d52F252CA60feFBcB73d7145dECee1804',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmUmANHKgjTJMwcKxfq963bQQBtxm4nJ8wpXxmGKC76zyS'
    },
    nft: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmQ5vsD9L6t4gUk2KKimptxbLoLdHWRmgYb9DVxSrwaUJb'
    },
    nftfyToken: {
      address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
      decimals: 18
    },
    p2p: {
      theGraph: '',
      contract: ''
    },
    marketplaceBlacklist: [],
    assets: [],
    rpcAddress: `${globalConfig.alchemy.mumbai.rpc}/${globalConfig.alchemy.mumbai.key}`,
    networkScanAddress: 'https://mumbai.polygonscan.com/',
    nftfyAuctionAddress: '',
    mint: {
      minterAddress: '',
      defaultCollectionAddress: ''
    },
    pinataBaseUrl: 'https://nftfypinningservice.azurewebsites.net/api/uploadMedia'
  },
  {
    chainId: 56,
    assets: [
      '0x9a48bd0ec040ea4f1d3147c025cd4076a2e71e3e',
      '0x49f2beff98ce62999792ec98d0ee4ad790e7786f',
      '0x78f225869c08d478c34e5f645d07a87d3fe8eb78'
    ],
    networkScanAddress: 'https://bscscan.com/',
    nft: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmUqzyQ1hEKk9qyaD9Hrp7VwJzhN3dUXGopVowLbwN9ACC'
    },
    p2p: {
      contract: '0x91FE09bB4D060abc2FD1460f87D19c4c9410448e',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmQXCmb3G5vNy9hkFK3ayj1T6AAKB2d1Ky5XUHFJ741nMD'
    },
    box: {
      contract: '0xFc44f66D5D689cD9108c6577533E9572f53a50Bc',
      contractAux: '',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmZUqKyQn4aNo8Xf4c4ywVQvwgUs5CLN3tjuQpC79WcJK3'
    },
    name: NetworksName.binance_smart_chain,
    fantasyName: 'BNB SC',
    networkTokenAddress: '0x0000000000000000000000000000000000000000',
    networkTokenDecimals: 18,
    networkTokenSymbol: 'BNB',
    networkTokenIcon: binanceTokenIcon,
    stablecoinAddress: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
    fracionalizerV1: {
      contract: '0x727638740980aA0aA0B346d02dd91120Eaac75ed',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmRtxqsMHKMbZhnqddMK9Toi5VtYnXCKs9ei4VTHvRnyxk'
    },
    fracionalizerV1_2: {
      contractFixedPrice: '0x8279BE8f740DBeDB05C0Ce165447E8bc1457a137',
      contractAuction: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmS3UnSnFbvfM1VQckFDWXwGnyT5Znzd7NSjMcSxco9q8B'
    },
    collectiveBuy: {
      contract: '0x1e95B705Dbbb3E1128445aFc06D9dA401552FDCb',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmYCZWRi86i1oukbEUvwdXgGKLtAKF1hZz89xKsnrhNzHC'
    },
    marketplace: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmTEnq1rYusRww67zMMhhUVrcQCoADbp61uZyC6sQMbs6p'
    },
    marketplaceBlacklist: [],
    nftfyToken: {
      address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
      decimals: 18
    },
    rpcAddress: 'https://bsc-dataseed.binance.org',
    erc20List: [
      {
        id: '1',
        name: 'Nftfy',
        symbol: 'NFTFY',
        address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      },
      {
        id: '1',
        name: 'BUSD Token',
        symbol: 'BUSD ',
        address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      },
      {
        id: '1',
        name: 'Wrapped BNB',
        symbol: 'WBNB',
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      }
    ],
    nftfyAuctionAddress: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
    mint: {
      minterAddress: '0x62cF87B0E441e6E3A1634304AbA6332F3Fd6464F',
      defaultCollectionAddress: '0x9c8a2b35B268bf2AA69f5fc105514e34daF3cEBb'
    },
    pinataBaseUrl: 'https://nftfypinningservice.azurewebsites.net/api/uploadMedia'
  },
  {
    assets: [
      '0x9a48bd0ec040ea4f1d3147c025cd4076a2e71e3e',
      '0x49f2beff98ce62999792ec98d0ee4ad790e7786f',
      '0x78f225869c08d478c34e5f645d07a87d3fe8eb78'
    ],
    networkScanAddress: 'https://testnet.bscscan.com/',
    nft: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmWBTJJqq1QFeeHzL4ef11WW5z6jVy4DwsdYgRq8KbJHEw'
    },
    p2p: {
      contract: '0x91FE09bB4D060abc2FD1460f87D19c4c9410448e',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmYkFVN5fe5JYz7JVjiiVqLFwpd3HoJvze2ic1pBmYGccz'
    },
    chainId: 97,
    box: {
      contract: '0xFc44f66D5D689cD9108c6577533E9572f53a50Bc',
      contractAux: '',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmccM4EjGYSFnBvHkCY4bzzNAjpJQE9jN4Jsj2DaBZw13C'
    },
    name: NetworksName.binance_testnet,
    networkTokenAddress: '0x0000000000000000000000000000000000000000',
    networkTokenDecimals: 18,
    networkTokenSymbol: 'BNB',
    fantasyName: 'BNB',
    networkTokenIcon: binanceTokenIcon,
    stablecoinAddress: '0x4fabb145d64652a948d72533023f6e7a623c7c53',
    fracionalizerV1: {
      contract: '0x727638740980aA0aA0B346d02dd91120Eaac75ed',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmRUFZ76FGrEYBTyTgapwLu6WadhhSg95nd8vuHUz4Lq6N'
    },
    fracionalizerV1_2: {
      contractFixedPrice: '0x8279BE8f740DBeDB05C0Ce165447E8bc1457a137',
      contractAuction: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmNVF1ffCdL4oPSDkgTygACrT2Emdfcozh9wNrPBka4Zue'
    },
    collectiveBuy: {
      contract: '0x1e95B705Dbbb3E1128445aFc06D9dA401552FDCb',
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmfVcgbrnjzFGsuY1xTcKi554zjacT62d7PCXNWwSiUfjT'
    },
    marketplace: {
      theGraph: 'https://api.thegraph.com/subgraphs/id/QmeVow1PTVD2CTwduJDoL2VtieWBsTLf6SQcc3BiRqRL9P'
    },
    marketplaceBlacklist: [],
    nftfyToken: {
      address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
      decimals: 18
    },
    rpcAddress: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    erc20List: [
      {
        id: '1',
        name: 'Nftfy',
        symbol: 'NFTFY',
        address: '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      },
      {
        id: '2',
        name: 'WBNB',
        symbol: 'WBNB',
        address: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
        balance: '0',
        decimals: 18,
        imageUrl: ''
      }
    ],
    nftfyAuctionAddress: '0x5EF37b60C374634Ff917BaafECB515Bf1482cAc3',
    mint: {
      minterAddress: '0x62cF87B0E441e6E3A1634304AbA6332F3Fd6464F',
      defaultCollectionAddress: '0x9c8a2b35B268bf2AA69f5fc105514e34daF3cEBb'
    },
    pinataBaseUrl: 'https://nftfypinningservice.azurewebsites.net/api/uploadMedia'
  }
]

export const chainConfig = (id: number): ChainConfigV2 => {
  const config = chainsConfig.find(chain => chain.chainId === id)

  if (!config) {
    // eslint-disable-next-line no-console
    console.error('No Config')
    return chainsConfig[0]
  }

  return config
}
