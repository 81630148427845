import mixpanel from 'mixpanel-browser'

interface Event {
  name: string
  data: { [key: string]: string | number }
  callback?: () => void
}

export const dataAnalyticsService = () => {
  return {
    trackEvent: ({ name, data, callback }: Event) => {
      mixpanel.track(name, data, { send_immediately: true }, callback)
    }
  }
}
